import React from 'react';
import html2canvas from "html2canvas";
import { Component, PropTypes, useEffect, useState } from 'react';
import { jsPDF } from "jspdf";
import APIHandler from '../../helper/APIHandler';
import moment from 'moment';

const Eprecriptiondownload = ({ prescriptionid,setPrescriptionid }) => {

    console.log("Rakesh id :", prescriptionid)
    const [prescriptionData, setPriscriptionData] = useState(null)
    const [prsid, setPrsid] = useState("")
    const [visible, setVisible] = useState(false)
    const [signature, setsinature] = useState("");
    useEffect(() => {
        if(prescriptionid!==null)
        getPrescription()

    }, [])

    useEffect(() => {

        if (visible) {
            pdfDownload()
            setPrescriptionid(null)
            setVisible(false)
        }

    }, [visible])




    const pdfDownload = () => {
        try {
            var w = document.getElementById("capture").offsetWidth;
            var h = document.getElementById("capture").offsetHeight;

            html2canvas(document.querySelector("#capture"), { useCORS: true }
            ).then(canvas => {
                var imgData = canvas.toDataURL(
                    'image/png');
                var doc = new jsPDF('L', 'px', [w, h], 'p', 'mm', 'a4');
                doc.addImage(imgData, 'JPEG', 0, 0, w, h);
                doc.save('E-prescription.pdf');

            });
            
        }
        catch (err) {

        }
    }

    const getPrescription = async () => {

        try {
            const result = await APIHandler.get(`bookings/prescriptions/${prescriptionid}`)
            if (result != null && result != undefined) {
                console.log(result.data, "API Rakesh")
                setPriscriptionData(result.data)
                setVisible(true)
               
            }
        }
        catch (e) {

        }



    }

    return (
        <>
            {prescriptionData !== null && prescriptionData !== undefined &&
                <div className='row' id="capture">
                <div className="col-lg-1"></div>
                <div className='col-lg-10 col-sm-12' id='full-width-tab'>
                    <div className='eprescription_print' id="testId">
                        {/* E-Prescriptions-Print Header */}
                        <div className='epres_header'>
                            <div className='epres_info_left'>

                                <div className='logo'><img src="images/images/fasttrackLogo.svg" /></div>
                                <div className='dr_info'>
                                    <h4>{prescriptionData[0].service_provider_name}</h4>
                                    <p>{prescriptionData[0].headline}</p>
                                    <p>{prescriptionData[0].city}</p>

                                </div>
                            </div>
                            <div className='epres_info_right'>
                                <h3>E-Prescription</h3>
                                <p>Teleconsultation</p>
                            </div>
                        </div>
                        {/* E-Prescriptions-Print Patient Info */}
                        <div className='epres_pate_info'>
                            <div className='row'>
                                <div className='col-4'>
                                    <div className='pate__list '>
                                        <h4>Name:</h4>
                                        <p>{prescriptionData[0].patient_name}</p>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='pate__list'>
                                        <h4>Booking ID: </h4>
                                        <p>{prescriptionData[0].booking_id}</p>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='pate__list'>
                                        <h4>Gender:</h4>
                                        <p>{prescriptionData[0].gender}</p>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='pate__list'>
                                        <h4>Consultation Date:</h4>
                                        <p>{moment(prescriptionData[0].created_at).format("DD MMM YYYY")}</p>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='pate__list'>
                                        <h4>Age:</h4>
                                        <p>{prescriptionData[0].age}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* E-Prescriptions-Print Body */}
                        {/* get html formate */}
                        <div className='epres_body'>
                            <div className='ep_details'>
                                <div style={{ whiteSpace: "pre-wrap" }}> {prescriptionData[0].prescription.replace('/n', '<br/>')}</div>

                            </div>
                            <div className='row'>
                                <div className='col-lg-7 col-7 eperi-t'>
                                    &nbsp;
                                </div>
                                <div className='col-lg-5 col-5'>
                                <div className='dr_sign'><img src={(prescriptionData[0].signature != null && prescriptionData[0].signature != "null") ? prescriptionData[0].signature : ""} style={{ widht: "120px", height: "50px" }} />
                                    </div>
                                    <div className='dr_signtext'>Signature </div>

                                   
                                </div>
                            </div>





                        </div>

                        {/* E-Prescriptions-Print Footer */}
                        <div className='epres_footer'>
                            <p>E-Prescription created from FastTrack Healthcare</p>
                        </div>




                    </div>
                </div>
                <div className="col-lg-1"></div>
            </div>
            }
        </>
    );

}
export default Eprecriptiondownload;