import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import RichTextEditor from 'react-rte';
import axios from "axios";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import { toast } from 'react-toastify';
import moment from 'moment';
import Eprecriptiondownload from "../Bookings/downloadeprescription"
import CircularProgress from '@mui/material/CircularProgress';
const baseUrl = process.env.REACT_APP_API_URL
const BookingDetails = () => {
    const location = useLocation()

    const bookingid = location.state?.bookingid
    const [prescriptionData, setPriscriptionData] = useState(null)
    const [tab, setTab] = useState('Booking Summary')
    const token = localStorage.getItem("token")
    const [Activities, setActivities] = useState({
        prescriptions: [],
        medicalReports: [],
        activityLog: []
        
    })
    const [prescription, setPrescription] = useState("")
    const [value, setValue] = useState(RichTextEditor.createValueFromString(prescription, 'markdown'));
    const [doctorId,setdoctorId]=useState("")
    const [prescriptionid, setPrescriptionid] = useState("");
    const { prescriptions, medicalReports, activityLog } = Activities
    const [title, settitle] = useState("")
    const [isLoaded, setIsLoaded] = useState(true)
    const [filepath, setfilepath] = useState("")

    const [show, setShow] = useState(false);

    const handleClose = () => {
        window.$("#modalmedicalreport").modal("hide")
    }
    const handleShow = () => {
        window.$("#modalmedicalreport").modal("show")
    }
    const [bookingData, setbookingData] = useState(
        {
            id: bookingid || 0,
            booking_type: "",
            notes: "",
            room_id: "",
            allergy: "",
            past_medical_issue: "",
            on_medication: "",
            remark: "",
            appointment_date: "",
            booking_date: "",
            booked_by: "",
            user_address: "",
            user_email: "",
            service_type: "",
            booked_for: "",
            pickup_address: "",
            is_video_consultancy: "",
            other_name: "",
            other_gender: "",
            other_mobile: "",
            other_age: "",
            short_description: "",
            total_amount: "",
            paid_amount: "",
            reason: "",
            status: "",
            is_active: "",
            provider_id: "",
            provider_name: "",
            provider_email: "",
            provider_mobile: "",
           
            service_provider_address: "",
            profile_pic: "",
            service: "",
            service_provider_user_id: "",
            tran_id: "",
            payment_mode: "",
            experience: "",
            degree: "",
            progress: "",
            is_confirmed:"",
            slots: [],
            specializations: [],
            medical_history: [],
            headline:"",


        }
    )
    const { id,
        booking_type,
        notes,
        room_id,
        allergy,
        past_medical_issue,
        on_medication,
        remark,
        appointment_date,
        booking_date,
        booked_by,
        user_address,
        user_email,
        service_type,
        booked_for,
        pickup_address,
        is_video_consultancy,
        other_name,
        other_gender,
        other_mobile,
        other_age,
        short_description,
        total_amount,
        paid_amount,
        reason,
        status,
        is_active,
        provider_id,
        provider_name,
        provider_email,
        provider_mobile,
        is_confirmed,
        service_provider_address,
        profile_pic,
        service,
        service_provider_user_id,
        tran_id,
        payment_mode,
        experience,
        degree,
        progress,
        slots,
        specializations,
        headline,
        medical_history } = bookingData;



    useEffect(() => {

        searchData(id)
        ActivityData(id)
       // alert(new Date().toISOString().slice(0, 10) +", " + new Date(appointment_date).toISOString().slice(0, 10)+" ," +appointment_date)
      
    }, [isLoaded])

    const searchData = async (id) => {
        var result = await APIHandler.get(`bookings/${bookingid}`)
        setdoctorId(result.data.doctor_id)
        setbookingData(result.data);
        getPrescription() 
    }
    //Acknowledge
    function randomID(len) {
        let result = '';
        if (result) return result;
        var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
            maxPos = chars.length,
            i;
        len = len || 5;
        for (i = 0; i < len; i++) {
            result += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return result;
    }
    const AcceptRejectBooking = async (status) => {
        //setIsActive(status)
        if (window.confirm('Are you sure to change booking status?')) {
        const roomid = randomID(5)
        var result = await APIHandler.put(`bookings/changebookingconfirmstatus/${bookingid}?status=${status}&room_id=${roomid}`)
        if (result != undefined) {
            if (result.success) {
                toast.success(result.message)
                searchData(bookingid)
            }
        }
       
    }
    }

    // Activity
    const ActivityData = async (id) => {
        var result = await APIHandler.get(`users/bookings/${bookingid}/activities`)
        setActivities(result.data);
    }
    //E-prescription

    const getPrescription = async () => {

        try {
            const result = await APIHandler.get(`bookings/${bookingid}/prescriptions`)
            if (result != null && result != undefined) {
                setPriscriptionData(result.data)
               
               
            }
        }
        catch (e) {

        }



    }

    const AddPrescriptionAPI = async (reqdata) => {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            }
            const response = await axios.post(`${baseUrl}doctors/prescription`, JSON.stringify(reqdata), headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }
    const AddPrescription = (e) => {
       
        
        var data = {
            "prescription": prescription,
            "user_id": doctorId,
            "booking_id": id
        }

        AddPrescriptionAPI(data).then(result => {
          
            window.$("#modalepriscription").modal("hide")
                setPrescription("")
                setValue(RichTextEditor.createValueFromString("", 'markdown'))
                ActivityData()

        })
        window.$("#modalepriscription").modal("hide")

    }
    const handleChange = (v) => {
        setValue(v)
        setPrescription(v.toString("markdown"))

    }

    const postmedical = async (e) => {

        try {
            setIsLoaded(false)
            var data = {
                "report_name": title,
                "attachment": filepath,
                "booking_id": id

            };

            var config = {

                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },

            };
            const result = await axios.post(`${baseUrl}users/medicalreports`, JSON.stringify(data), config)
            if (result !== undefined) {
                setIsLoaded(false)
                toast.success("Medical Report is Uploaded")
                settitle("")
                setfilepath("")
                window.$("#modalmedicalreport").modal("hide")
                medicalReports()
            }
        } catch (error) {
            toast.error(error)
        }

    }

    const postmedicalreport = async (e) => {
        setIsLoaded(false)

        var config = {
            headers: {
                'authorization': token,
            },
        };
        const selectedFile = e.target.files[0];
        // const selectedFileName = e.target.files[0];
        const formData = new FormData();
        formData.append("files", selectedFile);

        if((selectedFile.size/1000)>200)
        {
            toast.error("File should be less than 200kb")
        }
        else{
            const result = await axios.post(`${baseUrl}uploads?for=medical_report`, formData, config)
            if (result != null) {
                setfilepath(result.data.data.filePath)
                setIsLoaded(true)
            }

        }
       

    }

    return (
        <>
            <Layout />
            
            <div className="content-wrapper my_wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Booking ID : {bookingid}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <Link to="/bookings"><i className="bi bi-chevron-compact-left"></i> Back to Booking</Link>
                            </ol>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header d-flex p-0">
                                    <ul className="nav nav-pills p-2">
                                        <li className="nav-item"><a className="nav-link active" href="#tab_1" data-toggle="tab">Booking Summary </a></li>
                                        <li className="nav-item"><a className="nav-link" href="#tab_2" data-toggle="tab">Activities</a></li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="tab_1">
                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="Summary">
                                                    <Form.Label>Service</Form.Label>
                                                    <Form.Control name="service" value={service} />
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="Summary">
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Control name="status" value={status} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="Summary">
                                                    <Form.Label>Booking Date & Time</Form.Label>
                                                    <Form.Control name="bookingdate" value={moment(booking_date).utc().format("YYYY/MM/DD hh:mm A")} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="Summary">
                                                    <Form.Label>Appointment/Schedule Date</Form.Label>
                                                    <Form.Control name="appointment" value={moment(appointment_date).utc().format("YYYY/MM/DD hh:mm A")} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="Summary">
                                                    <Form.Label>Fee Paid</Form.Label>
                                                    <Form.Control name="feepaid" value={total_amount} />
                                                </Form.Group>

                                            </Row>
                                            <h5 className='bd_title'>Service Provider</h5>
                                            <div className="booking-box">

                                                <Row className="mb-3">
                                                    <Form.Group as={Col} controlId="formGridCity" className='col-4'>
                                                        <Form.Label>Name </Form.Label>
                                                        <Form.Control name="providername" value={provider_name} />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridState" className='col-8'>
                                                        <Form.Label>Address</Form.Label>
                                                        <Form.Control name="provideraddress" value={service_provider_address} />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label>Headline</Form.Label>
                                                        <Form.Control name="Headline" value={headline} />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control name="provideremail" value={provider_email} />
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <Form.Label>Mobile</Form.Label>
                                                        <Form.Control name="providermobile" value={provider_mobile} />
                                                    </Form.Group>

                                                </Row>
                                            </div>

                                            <h5 className='bd_title'>User Details</h5>
                                            <div className="booking-box">
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} controlId="formGridCity" className='col-4'>
                                                        <Form.Label>Name </Form.Label>
                                                        <Form.Control name="username" value={booked_by} />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridState" className='col-8'>
                                                        <Form.Label>Address</Form.Label>
                                                        <Form.Control name="useraddress" value={user_address} />
                                                    </Form.Group>

                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control name="useremail" value={user_email} />
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <Form.Label>Mobile</Form.Label>
                                                        <Form.Control name="usermobile" value={other_mobile} />
                                                    </Form.Group>

                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <Form.Label>Booked For</Form.Label>
                                                        <Form.Control name="email" value={booked_for} />
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control name="bookedfor" value={other_name} />
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <Form.Label>Age</Form.Label>
                                                        <Form.Control name="age" value={other_age} />
                                                    </Form.Group>

                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <Form.Label>Medical History (If Any)</Form.Label>
                                                        <Form.Control as="textarea" rows={2} name="medicalhistory" />
                                                    </Form.Group>

                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <Form.Label>Note (If Any)</Form.Label>
                                                        <Form.Control as="textarea" rows={2} name="note" value={notes} />
                                                    </Form.Group>

                                                </Row>
                                            </div>

                                            <div className="acknowledge">
                                                <Row className="">
                                                    <Form.Group as={Col} controlId="formGridState">
                                                        
                                                    {((new Date(appointment_date)) >(new Date()))?
                                                    ( !is_confirmed)?
                                                            <>
                                                            <button className="btn btn-primary" onClick={e => AcceptRejectBooking(true)}>Acknowledge </button>                                                           
                                                           </>
                                                            :<button className="btn btn-danger" onClick={e => AcceptRejectBooking(false)}>Cancel </button>
                                                            :""  }                                                      
                                                    </Form.Group>

                                                </Row>
                                            </div>



                                        </div>

                                        <div className="tab-pane" id="tab_2">

                                            <div className='row user-action-box'>
                                                <div className="col-12">
                                                    <h5 className='bd_title'>E-Prescriptions {(status==="Booked" || status==="Completed") && is_confirmed===1 ?<a onClick={() => { window.$("#modalepriscription").modal("show") }} className='ml-1' title='Add Prescriptions'> <i className='fa fa-plus-circle'></i></a>:"" }  </h5>
                                                    <div className="">
                                                        <div className="">
                                                            <table className="table table-striped  table-bordered " >
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            E-Prescription
                                                                        </th>
                                                                        <th>
                                                                            Uploaded On
                                                                        </th>

                                                                        <th>
                                                                            Download
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {prescriptions.map(obj => (
                                                                        <tr>
                                                                            <td>E-Prescription</td>
                                                                            <td>{moment(obj.created_at).utc().format("YYYY/MM/DD hh:mm A")}  </td>
                                                                             <td><a onClick={() => setPrescriptionid(obj.id)} style={{ cursor: "pointer" }}> Download</a></td> 
                                                                           
                                                                            {/* <td><a href={obj.prescription_file} target="_blank"> view</a></td> */}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <h5 className='bd_title'>Reports <a onClick={handleShow} className='add_mr ml-1' style={{ cursor: 'pointer' }} title='Add Reports'> <i className='fa fa-plus-circle'></i></a></h5>
                                                    <div className="">
                                                        <div className="">
                                                            <table className="table table-striped  table-bordered " >
                                                                <thead>

                                                                    <tr>
                                                                        <th>
                                                                            Title
                                                                        </th>
                                                                        <th>
                                                                            Uploaded On
                                                                        </th>
                                                                        <th>
                                                                            View
                                                                        </th>
                                                                        {/* <th>
                                                                            Download
                                                                        </th> */}

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {medicalReports.map(obj => (
                                                                        <tr>
                                                                            <td>{obj.report_name}</td>

                                                                            <td>{moment(obj.report_date).utc().format("YYYY/MM/DD hh:mm A")}  </td>
                                                                            <td><a href={obj.attachment} target="_blank">View</a> </td>
                                                                            {/* <td><a href={obj.attachment} target="_blank">Download</a> </td> */}


                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <h5 className='bd_title'>Activity Logs</h5>
                                                    <div className="">
                                                        <div className="">
                                                            <table className="table table-striped  table-bordered " >
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Title
                                                                        </th>
                                                                        <th>
                                                                            Performed By
                                                                        </th>
                                                                        <th>
                                                                            Log Date Time
                                                                        </th>


                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {activityLog.map(obj => (
                                                                        <tr>
                                                                            <td>{obj.title}</td>
                                                                            <td>{obj.performed_by}</td>

                                                                            <td>{moment(obj.log_date_time).utc().format("YYYY/MM/DD hh:mm A")}  </td>


                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>



                                        </div>



                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>




                </section>
            </div>
            <div className="modal fade" id="modalepriscription" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content" style={{ width: "800px" }}>
                        {/* <!-- Modal header --> */}
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalforprofile">
                                E-Prescription
                            </h5>
                            <button
                                type="button"
                                class="btn-close pull-right close" data-bs-dismiss="Close" aria-label="Close"
                                style={{ marginTop: "-24px" }}
                                onClick={() => { window.$("#modalepriscription").modal("hide") }}
                            >&times;</button>

                        </div>
                        <div className="modal-body ">
                            {/* <!-- Form START --> */}
                            <RichTextEditor
                                value={value}
                                onChange={handleChange}
                                className="ritch-box" />


                        </div>


                        <div className="modal-footer d-flex justify-content-between">
                            <p></p>
                            <button type="button" className="btn btn-sm btn-primary-soft"
                                onClick={AddPrescription}
                            >
                                Save
                            </button>



                        </div>
                    </div>

                    {/* <!-- Form END --> */}
                </div>
                {/* <!-- Modal footer --> */}
            </div>

            <div>
                {prescriptionid !== undefined && prescriptionid !== null && prescriptionid !== "" ?
                    <div id="capture">
                        <Eprecriptiondownload prescriptionid={prescriptionid} setPrescriptionid={setPrescriptionid}></Eprecriptiondownload>
                    </div>
                    : ""
                }
               
            </div>

            <div
                class={"modal fade"}
                id="modalmedicalreport"
                tabIndex="-1"
                aria-hidden="false"
            >
                <div class="modal-dialog mydailog">
                    <div class="modal-content">
                        {/* <!-- Modal header --> */}
                        <div class="modal-header">
                            <h5 class="modal-title pull-left" id="modalpris">  Add Medical Report </h5>
                            <button type="button" onClick={(e) => handleClose(e)} class="btn-close pull-right" data-bs-dismiss="Close" aria-label="Close">&times;</button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div class="col-lg-12">
                                    <div class="fd_input mb-2">
                                        <div class="form-group"><label>Title</label>
                                            <input type="text" name="name" class="form-control" placeholder="" value={title} onChange={(e) => settitle(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="fd_input mb-2">
                                        <div class="form-group"><label>File/Attachment</label>


                                            <input type={'file'} name="name" class="form-control" placeholder="" onChange={(e) => postmedicalreport(e)} />
                                           <span style={{color:"red"}}> Max file size 200 KB</span>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <button className='btn btn-info' onClick={(e) => postmedical(e)} disabled={!isLoaded}> {!isLoaded && <CircularProgress color="inherit" size={16} />} Upload</button>
                        </div>
                    </div>

                    {/* <!-- Form END --> */}
                </div>
                {/* <!-- Modal footer --> */}
            </div>
        </>
    )
}
export default BookingDetails 