import { lazy } from 'react';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';

import RoutesProtected from '../components/protectRoutes';
import Revenus from '../pages/Revenus/Revenus';
import Reports from '../pages/Reports/Reports';
import Layout from '../pages/layout/layout';
import UserProfile from '../pages/Users/UserProfile';
import BookingDetails from '../pages/Bookings/BookingDetails';
import Login from '../pages/Login';
import Masters from '../pages/Dashboard/Masters';
import ViewContactUs from '../pages/Reports/viewContactUs';
import { Suspense } from 'react';
import BookingsDash from '../pages/Bookings/Bookingsdash';
import Query from '../pages/Bookings/Query';
import Payoutdash from '../pages/Bookings/Payoutdash'



const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const Bookings = lazy(() => import('../pages/Bookings/Bookings'));
const Payout = lazy(() => import('../pages/Payouts/Payout'));
const Users = lazy(() => import('../pages/Users/users'));
const UserDash = lazy(() => import('../pages/Users/userDash'));
const Coupon = lazy(() => import('../pages/Coupon/Coupon'));
const HomecareVisits = lazy(() => import('../pages/Bookings/HomecareBookingVisits'));

const Alltheroutes = () => {
    return (<>
        <Suspense fallback={<div className='loding-default'>...Loading</div>}>
            <HashRouter>
                {/* main component routes */}

                <Routes>

                    <Route path="/" element={<Login />} />
                    <Route path="dashboard" element={<RoutesProtected cmp={Dashboard} />} />
                    <Route path="bookings" element={<RoutesProtected cmp={Bookings} />} />
                    <Route path="payouts" element={<RoutesProtected cmp={Payout} />} />
                    <Route path="users" element={<RoutesProtected cmp={Users }/>} />
                    <Route path="profile" element={<RoutesProtected cmp={UserProfile} />} />
                    <Route path="revenus" element={<RoutesProtected cmp={Revenus} />} />
                    <Route path="reports" element={<RoutesProtected cmp= {Reports} />} />
                    <Route path="booking-detail" element={<RoutesProtected cmp={BookingDetails} />} />
                    <Route path="masters" element={<RoutesProtected cmp={Masters} />} />
                    <Route path="contactUs" element={<RoutesProtected cmp={ViewContactUs} />} />
                    <Route path="bookingsdash" element={<RoutesProtected cmp={BookingsDash} />} />
                    <Route path="query" element={<RoutesProtected cmp={Query} />} />
                    <Route path="payoutdash" element={<RoutesProtected cmp={Payoutdash} />} />
                    <Route path="userdash" element={<RoutesProtected cmp={UserDash} />} />
                    <Route path="coupon" element={<RoutesProtected cmp={Coupon}/>} />
                    <Route path="homecarevisits" element={<RoutesProtected cmp={HomecareVisits} />} />
                    
          
                </Routes>


            </HashRouter>
        </Suspense>

    </>);

}
export default Alltheroutes