
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from "react-router-dom"
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';

import { Loader, Placeholder } from 'rsuite';
import { queries } from '@testing-library/react';
let PageSize = 10;


const Payouts = () => {
    const location = useLocation();
    const [Period, setPeriod] = useState("ThisYear");
    const [Concern, SetConcern] = useState("");
    const [ToDate, setToDate] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [SearchBy, setSearchBy] = useState("");

    const [userData, setUserData] = useState([]);

    const [totalUser, setTotalUser] = useState("");

    const [isSending, setIsSending] = useState(false)
    const [isLoaded, SetisLoaded] = useState(true)
    const [allUserData, setAllUserData] = useState([])

    //paging
    const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {

        if (isSending) {
            setIsSending(false);

        }
        var p = Period;
        searchData(Period, Concern, ToDate, FromDate, SearchBy)


    }, [isSending])

    const searchData = async (Period, Concern, ToDate, FromDate, SearchBy) => {

        var result
        result = await APIHandler.get(`/dashboard/admin/payout`)
        
        setTotalUser(result.message);
        setAllUserData(result.data)
        setUserData(result.data);

        SetisLoaded(true)
        location.state = undefined;
    }


    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return userData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, userData]);


    return (
        <div>
            <Layout />
            <div className="content-wrapper my_wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Payouts</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Payouts</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="row">


                        {Period === "Custom" &&
                            <>
                                <div className="col-lg-2">
                                    <label>From Date</label>
                                    <input type="date" className="form-control" placeholder="From Date" value={FromDate} onChange={(e) => setFromDate(e.target.value)} />
                                </div>
                                <div className="col-lg-2">
                                    <label>To Date</label>
                                    <input type="date" className="form-control" placeholder="To Date" value={ToDate} onChange={(e) => setToDate(e.target.value)} />
                                </div>

                            </>
                        }
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-primary mt-4">
                                <div className="card-header">
                                    <h3 className="card-title">Pending Payouts</h3>
                                </div>
                                <div className="card-body">
                                    <table className="table table-striped  table-bordered " >
                                        <thead>
                                            <tr>
                                                <th>
                                                    Service provider
                                                </th>

                                                <th>
                                                    Service
                                                </th>

                                                <th>
                                                    Paid
                                                </th>
                                                <th>
                                                    Payment Amount
                                                </th>
                                                <th>
                                                    Transaction Id
                                                </th>
                                                <th>
                                                    Created On
                                                </th>
                                                

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoaded ? (
                                                    (currentTableData !== null && currentTableData.length > 0) ?
                                                        currentTableData.map(obj => (
                                                            <tr>
                                                                <td>{obj.name}</td>
                                                                <td>{obj.service_type}</td>
                                                                <td>{obj.paid}</td>
                                                                <td>{obj.payment_amount} </td>
                                                                <td>{obj.transaction_id} </td>
                                                                <td>
                                                                    {moment(obj.created_at).format("YYYY/MM/DD")}
                                                                </td>
                                                                
                                                                {/* <td><a href={obj.attachment} target="_blank"> view</a></td> */}


                                                            </tr>
                                                        )) : <tr><td colSpan={8}>No data found</td></tr>) : <tr><td colSpan={8}>  <div className="loader"></div></td></tr>}

                                        </tbody>
                                    </table>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={userData.length}
                                        pageSize={PageSize}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </div>
    )
}
export default Payouts
