import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from "axios";
import { get } from 'http';
const baseUrl = process.env.REACT_APP_API_URL

const UserProfile = () => {
    const token = localStorage.getItem("token")
    const location = useLocation()
    const userid = location.state?.userid
    const [tab, setTab] = useState('profile details')

    const [btnApprove, setTextApprove] = useState('Approve')
    const [btnReject, setTextReject] = useState('Reject')
    const [approvalDateOfDoctor, setApprovalDateOfDoctor] = useState('')
    const [approvalDateOfHomecare, setApprovalDateOfHomecare] = useState("")
    const [approvalDateOfAmbulance, setApprovalDateOfAmbulance] = useState("")
    const [isDoctor, setisDoctor] = useState("")
    const [isHomecare, setisHomecare] = useState("")
    const [isAmbulance, setisAmbulance] = useState("")
    const [isCallDoctor, setCallDoctor] = useState(null)
    const [isActive, setIsActive] = useState(false)
    const [isApprove, setIsApprove] = useState(false)
    const [reason, setReason] = useState("")
    const [homecareProfileData, setHomecareProfileData] = useState("")
    const [ambulanceProfileData, setAmbulanceProfileData] = useState("")
    const [ambulanceDetail, setAmbulanceDetail] = useState("")



    const [userForm, setUserForm] = useState(
        {
            id: userid || 0,
            name: "",
            mobile: "",
            email: "",
            gender: "",
            state: "",
            city: "",
            zipcode: "",
            address: "",
            history: "",
            notes: "",
            complete_address: "",
            medical_history: "",
            profile_pic: null,
        }
    )


    const { id, name, mobile, email, gender, state, city, zipcode, complete_address, notes, medical_history, profile_pic } = userForm;
    const [teleconsultationData, setTeleconsultationData] = useState({

        headline: '',
        specialization: [],
        city: "",
        bio: '',
        address: "",
        availability: [],
        consultation_fees: "",
        degree: '',
        followup_fees: "",
        service_provider_document: [],
        state: "",
        zipcode: '',
        gender: '',
        experience: '',
        spelization_id: '',
        applied_on: '',
        approved_date: '',
        signature: null

    })


    const { headline, bio,
        degree, followup_fees,
        specialization, availability, service_provider_document,
        consultation_fees,
        experience, spelization_id, applied_on, approved_date, signature, address } = teleconsultationData;


    useEffect(() => {

        searchData(id)

        ConsultationData(id)

        setCallDoctor(false)

        getHomecareProfileData(id)
        getAmbulanceProfileData(id)


    }, [])



    const searchData = async (id) => {
        var result = await APIHandler.get(`users/${id}`)
        //var result = await APIHandler.get(`doctors/${id}/profile`)
        setUserForm(result.data);
    }

    // Teleconsultation


    const ConsultationData = async (id) => {

        try {
            var result = await APIHandler.get(`doctors/${id}/profileforAdmin`)

            if (result.data !== null && result.data !== undefined) {

                setTeleconsultationData(result.data);
                setisDoctor("Yes")
                if (result.data.is_active) {
                    setIsActive(true)
                }
                else {
                    setIsActive(false)
                }
                if (result.data.is_approved === 1) {
                    setIsApprove(true)
                    setTextApprove("Activate");
                    setTextReject("Block");
                    setApprovalDateOfDoctor(" Approved On : " + moment(result.data.approved_date).format("YYYY/MM/DD"));

                }
                else {
                    setTextApprove("Approve");
                    setTextReject("Reject");
                    setApprovalDateOfDoctor(" Applied On : " + moment(result.data.applied_on).format("YYYY/MM/DD"));
                    setIsApprove(false)
                }
            }
            else {
                setisDoctor("No")
                setTeleconsultationData("")
            }
        }
        catch (error) {

        }
    }

    const getHomecareProfileData = async (id) => {
        try {
            var result = await APIHandler.get(`/homecares/${id}/profile`)
            if (result.data !== null && result.data !== undefined) {
                setHomecareProfileData(result.data);
                setisHomecare("Yes")
            }
            if (result.data.is_approved === 1) {
                setApprovalDateOfHomecare(" Approved On : " + moment(result.data.approved_date).format("YYYY/MM/DD"));
            }
        }
        catch (error) {
            setisHomecare("No")
            setHomecareProfileData("")
        }
    }

    const getAmbulanceProfileData = async (id) => {
        try {
            var result = await APIHandler.get(`ambulances/profileDetail/${id}`)
            if (result.data !== null && result.data !== undefined) {
                setAmbulanceProfileData(result.data);
                setisAmbulance("Yes")
            }
            if (result.data.is_approved === 1) {
                setApprovalDateOfAmbulance(" Approved On : " + moment(result.data.approved_date).format("YYYY/MM/DD"));
            }
        }
        catch (error) {
            setisAmbulance("No")
            setAmbulanceProfileData("")
        }

    }

    const getAmbulanceDetailById = async (id) => {
        try {
            var result = await APIHandler.get(`ambulances/detail/${id}`)
            if (result.data !== null && result.data !== undefined) {
                setAmbulanceDetail(result.data);
            }
        }
        catch (error) {
        }
    }



    //update status
    const ApproveOrRejectForService = async (e, status, service) => {

        var result = await APIHandler.patch(`users/${id}/services/update?service=${service}&changeFor=Approval&status=${status}`)

        if (result != undefined) {
            if (result.data.is_approved = true && result.data.status === 'Approved') {

                toast.success("Application approved")
            }
            else {
                toast.error("Application rejected")
            }
        }
        if (service === 'Doctor')
            ConsultationData(id)
        else if (service === 'Nurse')
            getHomecareProfileData(id)
        else if (service === 'Ambulance')
            getAmbulanceProfileData(id)
    }

    const rejectApplication = async (e, status, service) => {
        try {
            if (reason === "") return toast.error("Please enter reason of rejection")
            var data = {
                "reason": reason,
                "id": id,
                "status": status
            }
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            }

            const response = await axios.patch(`${baseUrl}/users/${id}/services/update?service=${service}&changeFor=Approval&status=${status}`, data, headers).then((response) => {
                if (response != undefined) {
                    toast.error("Application rejected")
                }
                setReason("")

                if (service === 'Doctor') {
                    window.$("#exampleModal-001").modal("hide")
                    ConsultationData(id)
                }
                else if (service === 'Nurse') {
                    window.$("#exampleModal-0012").modal("hide")
                    getHomecareProfileData(id)
                }
                else if (service === 'Ambulance') {
                    window.$("#exampleModal-0013").modal("hide")
                    getAmbulanceProfileData(id)
                }

                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }


    }

    const ChangeUserStatus = async (status, service) => {
        //setIsActive(status)
        var result = await APIHandler.patch(`users/${id}/services/update?service=${service}&changeFor=Active&status=${status}`)
        if (result != undefined) {
            if (result.success) {
                if (result.data.is_active) {
                    toast.success("User successfully activated")
                }
                else {
                    toast.success("User successfully blocked")
                }
            }
        }
        if (service === 'Doctor')
            ConsultationData(id)
        else if (service === 'Nurse')
            getHomecareProfileData(id)
        else if (service === 'Ambulance')
            getAmbulanceProfileData(id)


    }

    const ApproveOrRejectAmbulance = async (ambulanceId, status) => {
        var data = {
            "status": status,
            "rejection_reason": reason,
        }
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': token
            }
        }

        const response = await axios.put(`${baseUrl}/ambulances/approveOrReject/${ambulanceId}`, data, headers).then((response) => {
            if (response != undefined) {
                if (response.data.success === true) {

                    if (status === 'Approved') {
                        toast.success("Application approved")
                    }
                    else {
                        toast.error("Application rejected")
                    }
                    // to hide the modal
                    window.$("#myModal").modal("hide")
                    getAmbulanceProfileData(id)
                }
            }
        }
        ).catch(error => {
            if (error.response.status === 400) {
                if (error.response.data.success === false)
                    return toast.error(error.response.data.message)
            }
            else if (error.response.status === 401) {
                return toast.error("You are not authorized to view this page")
            }
            else if (error.response.status === 404) {
                return toast.error("Url not found")
            }
            else if (error.response.status === 500) {
                return toast.error("Internal server error please try after sometime")
            }
        });
        return response.data

    }


    return (
        <>
            <Layout />

            <div className="content-wrapper my_wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{name}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <Link to="/users"><i className="bi bi-chevron-compact-left"></i> Back to user</Link>
                            </ol>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header d-flex p-0">
                                    <ul className="nav nav-pills p-2">
                                        <li className="nav-item"><a className="nav-link active" href="#tab_1" data-toggle="tab">Personal Details </a></li>
                                        <li className="nav-item"><a className="nav-link" href="#tab_2" data-toggle="tab" onClick={() => setCallDoctor(true)}>Teleconsultation</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#tab_3" data-toggle="tab" onClick={() => setCallDoctor(true)}>Homecare</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#tab_4" data-toggle="tab" onClick={() => setCallDoctor(true)}>Ambulance</a></li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="tab_1">


                                            <div className="row mb-3">
                                                <div className='col-lg-9'>
                                                    <div className="row">
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                                <Form.Label>Name</Form.Label>
                                                                <Form.Control name="name" value={name} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridState" className='mb-3'>
                                                                <Form.Label>Phone </Form.Label>
                                                                <Form.Control name="mobile" value={mobile} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                                <Form.Label>Email </Form.Label>
                                                                <Form.Control name="email" value={email}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridState" className='mb-3'>
                                                                <Form.Label>Gender</Form.Label>
                                                                <Form.Control name="gender" value={gender} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                                <Form.Label>State</Form.Label>
                                                                <Form.Control name="state" value={state} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridState" className='mb-3'>
                                                                <Form.Label>City</Form.Label>
                                                                <Form.Control name="city" value={city} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <Form.Group as={Col} controlId="formGridState" className='mb-3'>
                                                                <Form.Label>Zip Code</Form.Label>
                                                                <Form.Control name="zipcode" value={zipcode} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-8'>
                                                            <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                                <Form.Label>Complete Address</Form.Label>
                                                                <Form.Control name="address" value={complete_address} />
                                                            </Form.Group>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="tele-profile-box mt-4 ">
                                                        <div className="img-profile"><img src={(profile_pic !== null && profile_pic !== "null") ? profile_pic : "dist/img/avatar.png"} /></div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className='col-lg-12'>
                                                    <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                        <Form.Label>Medical History(if any)</Form.Label>
                                                        <Form.Control as="textarea" rows={2} name="history" value={medical_history} />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <Form.Group as={Col} controlId="formGridCity" className='mb-3'>
                                                        <Form.Label>Note (if any)</Form.Label>
                                                        <Form.Control as="textarea" rows={2} name="note" value={notes} />
                                                    </Form.Group>
                                                </div>
                                            </div>



                                        </div>

                                        <div className="tab-pane" id="tab_2">
                                            {
                                                isDoctor === "Yes" || isDoctor === "yes" ?
                                                    <>

                                                        <div className='user-action-box'>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <Form.Group as={Col} controlId="tele">
                                                                        <Form.Label>{approvalDateOfDoctor}</Form.Label>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="col-lg-6 text-right">
                                                                    <Form.Group as={Col} controlId="tele">
                                                                        {(!isApprove) ?
                                                                            <>
                                                                                <input type="Submit" name="btnApprove" value={btnApprove} className="btn btn-success" onClick={e => ApproveOrRejectForService(e.target.value, true, 'Doctor')} />
                                                                                <input name="btnReject" value={btnReject} className="btn btn-danger ml-3" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal-001" />
                                                                                {/* onClick={e => UpdateUserStatus(e.target.value, false)} */}


                                                                                <div class="modal fade" id="exampleModal-001" tabindex="-1" aria-labelledby="exampleModalLabel-001" aria-hidden="true">
                                                                                    <div class="modal-dialog">
                                                                                        <div class="modal-content">
                                                                                            <div class="modal-header">
                                                                                                <h4 class="modal-title fs-5" id="exampleModalLabel-001">Reason Of Rejection</h4>
                                                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                                                                                            </div>
                                                                                            <div class="modal-body">

                                                                                                <textarea rows="5" col="46" placeholder='Type reason of rejection for sending mail......' value={reason} onChange={e => setReason(e.target.value)}></textarea>
                                                                                            </div>
                                                                                            <div class="modal-footer">

                                                                                                <button type="button" class="btn btn-primary" onClick={e => rejectApplication(e.target.value, false, 'Doctor')}>Confirm</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : <input type="Submit" name="btnApprove" value={isActive ? "Block" : "Active"} className={isActive ? "btn btn-danger" : "btn  btn-success"} onClick={e => ChangeUserStatus(!isActive, 'Doctor')} />
                                                                        }

                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row ">
                                                            <div className="col-lg-9">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3' >
                                                                    <Form.Label>Headline</Form.Label>
                                                                    <Form.Control name="Headline" value={headline} />

                                                                </Form.Group>


                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Brief Details</Form.Label>
                                                                    <Form.Control as="textarea" rows={2} name="details" value={bio} />
                                                                </Form.Group>
                                                            </div>

                                                            <div className="col-lg-3">
                                                                <div className="tele-profile-box mt-4">
                                                                    <div className="img_sign">{signature !== null && signature !== "null" ? <img src={signature} /> : "Signature Not Uploaded"}</div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div className="row mt-4">
                                                            <div className="col-lg-3">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Total Experience (in years)</Form.Label>
                                                                    <Form.Control name="experiance" value={experience} />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Degree</Form.Label>
                                                                    <Form.Control name="degree" value={degree} />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Consultation Fee</Form.Label>
                                                                    <Form.Control name="consultationfee" value={consultation_fees} />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Followup Fee</Form.Label>
                                                                    <Form.Control name="followupfee" value={followup_fees} />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Specialization</Form.Label>
                                                                    <table className="table table-striped  table-bordered " >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    Specialization
                                                                                </th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {specialization.map(obj => (
                                                                                <tr>
                                                                                    <td>{obj.type}</td>

                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>AddAvibility</Form.Label>
                                                                    <div className="">
                                                                        <table className="table table-striped  table-bordered" >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        Days
                                                                                    </th>
                                                                                    <th>
                                                                                        From Time
                                                                                    </th>
                                                                                    <th>
                                                                                        To Time
                                                                                    </th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {availability.map(obj => (
                                                                                    <tr>
                                                                                        <td>{obj.day == 0 ? 'Sunday' : obj.day == 1 ? 'Monday' : obj.day == 2 ? 'Tuesday' : obj.day == 3 ? 'Wednesday' : obj.day == 4 ? 'Thursday' : obj.day == 5 ? 'Friday' : obj.day == 6 ? 'Saturday' : ''}</td>
                                                                                        <td>{obj.start_time}</td>
                                                                                        <td>{obj.end_time}</td>

                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Documents</Form.Label>

                                                                    <div className="">
                                                                        <table className="table table-striped  table-bordered" >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        Document Type
                                                                                    </th>
                                                                                    <th>
                                                                                        Title
                                                                                    </th>
                                                                                    <th>
                                                                                        View
                                                                                    </th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {service_provider_document.map(obj => (
                                                                                    <tr>
                                                                                        <td>{obj.document_type}</td>
                                                                                        <td>{obj.title}</td>
                                                                                        <td><a href={obj.doc_file_path} target="_blank"> View</a> </td>

                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>


                                                        </div>
                                                    </> : "Not a Doctor"

                                            }

                                        </div>


                                        <div className="tab-pane" id="tab_3">
                                            {
                                                isHomecare === "Yes" || isHomecare === "yes" ?
                                                    <>

                                                        <div className='user-action-box'>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <Form.Group as={Col} controlId="tele">
                                                                        <Form.Label>{approvalDateOfHomecare}</Form.Label>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="col-lg-6 text-right">
                                                                    <Form.Group as={Col} controlId="tele">
                                                                        {(!homecareProfileData.is_approved) ?
                                                                            <>
                                                                                <input type="Submit" name="btnApprove" value="Approve" className="btn btn-success" onClick={e => ApproveOrRejectForService(e.target.value, true, 'Nurse')} />
                                                                                <input name="btnReject" value="Reject" className="btn btn-danger ml-3" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal-0012" />
                                                                                {/* onClick={e => UpdateUserStatus(e.target.value, false)} */}
                                                                                <div class="modal fade" id="exampleModal-0012" tabindex="-1" aria-labelledby="exampleModalLabel-0012" aria-hidden="true">
                                                                                    <div class="modal-dialog">
                                                                                        <div class="modal-content">
                                                                                            <div class="modal-header">
                                                                                                <h4 class="modal-title fs-5" id="exampleModalLabel-0012">Reason Of Rejection</h4>
                                                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                                                                                            </div>
                                                                                            <div class="modal-body">

                                                                                                <textarea rows="5" col="46" placeholder='Type reason of rejection for sending mail......' value={reason} onChange={e => setReason(e.target.value)}></textarea>
                                                                                            </div>
                                                                                            <div class="modal-footer">

                                                                                                <button type="button" class="btn btn-primary" onClick={e => rejectApplication(e.target.value, false, 'Nurse')}>Confirm</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : <input type="Submit" name="btnApprove" value={homecareProfileData.is_active ? "Block" : "Active"} className={homecareProfileData.is_active ? "btn btn-danger" : "btn  btn-success"} onClick={e => ChangeUserStatus(!homecareProfileData.is_active, 'Nurse')} />
                                                                        }

                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row ">
                                                            <div className="col-lg-9">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3' >
                                                                    <Form.Label>Headline</Form.Label>
                                                                    <Form.Control name="Headline" value={homecareProfileData.headline} />

                                                                </Form.Group>


                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Brief Details</Form.Label>
                                                                    <Form.Control as="textarea" rows={2} name="details" value={homecareProfileData.bio} />
                                                                </Form.Group>
                                                            </div>

                                                            {/* <div className="col-lg-3">
                                                                <div className="tele-profile-box mt-4">
                                                                    <div className="img_sign">{signature !== null && signature !== "null" ? <img src={signature} /> : "Signature Not Uploaded"}</div>
                                                                </div>
                                                            </div> */}

                                                        </div>


                                                        <div className="row mt-4">
                                                            <div className="col-lg-3">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Total Experience (in years)</Form.Label>
                                                                    <Form.Control name="experiance" value={homecareProfileData.experience} />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Degree</Form.Label>
                                                                    <Form.Control name="degree" value={homecareProfileData.degree} />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Services</Form.Label>
                                                                    <table className="table table-striped  table-bordered " >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    Services
                                                                                </th>
                                                                                <th>
                                                                                    type
                                                                                </th>
                                                                                <th>
                                                                                    per visit charge
                                                                                </th>
                                                                                <th>
                                                                                    two hour charge
                                                                                </th>
                                                                                <th>
                                                                                    four hour charge
                                                                                </th>
                                                                                <th>
                                                                                    six hour charge
                                                                                </th>
                                                                                <th>
                                                                                    twelve hour charge
                                                                                </th>
                                                                                <th>
                                                                                    twenty four hour charge
                                                                                </th>


                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {homecareProfileData.services.map(obj => (
                                                                                <tr>
                                                                                    <td>{obj.name}</td>
                                                                                    <td>{obj.homecare_type}</td>
                                                                                    <td>{obj.per_visit_charge}</td>
                                                                                    <td>{obj.two_hours_charge}</td>
                                                                                    <td>{obj.four_hours_charge}</td>
                                                                                    <td>{obj.six_hours_charge}</td>
                                                                                    <td>{obj.twelve_hours_charge}</td>
                                                                                    <td>{obj.twenty_four_hours_charge}</td>

                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>AddAvibility</Form.Label>
                                                                    <div className="">
                                                                        <table className="table table-striped  table-bordered" >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        Days
                                                                                    </th>
                                                                                    <th>
                                                                                        From Time
                                                                                    </th>
                                                                                    <th>
                                                                                        To Time
                                                                                    </th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {homecareProfileData.availability.map(obj => (
                                                                                    <tr>
                                                                                        <td>{obj.day == 0 ? 'Sunday' : obj.day == 1 ? 'Monday' : obj.day == 2 ? 'Tuesday' : obj.day == 3 ? 'Wednesday' : obj.day == 4 ? 'Thursday' : obj.day == 5 ? 'Friday' : obj.day == 6 ? 'Saturday' : ''}</td>
                                                                                        <td>{obj.start_time}</td>
                                                                                        <td>{obj.end_time}</td>

                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Documents</Form.Label>

                                                                    <div className="">
                                                                        <table className="table table-striped  table-bordered" >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        Document Type
                                                                                    </th>
                                                                                    <th>
                                                                                        Title
                                                                                    </th>
                                                                                    <th>
                                                                                        View
                                                                                    </th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {homecareProfileData.service_provider_document.map(obj => (
                                                                                    <tr>
                                                                                        <td>{obj.document_type}</td>
                                                                                        <td>{obj.title}</td>
                                                                                        <td><a href={obj.attachment} target="_blank"> View</a> </td>

                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>


                                                        </div>
                                                    </> : "Not a Homecare"

                                            }

                                        </div>


                                        <div className="tab-pane" id="tab_4">
                                            {

                                                isAmbulance === "Yes" || isAmbulance === "yes" ?
                                                    <>

                                                        <div className='user-action-box'>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <Form.Group as={Col} controlId="tele">
                                                                        <Form.Label>{approvalDateOfAmbulance}</Form.Label>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="col-lg-6 text-right">
                                                                    <Form.Group as={Col} controlId="tele">
                                                                        {(!ambulanceProfileData.is_approved) ?
                                                                            <>
                                                                                <input type="Submit" name="btnApprove" value="Approve" className="btn btn-success" onClick={e => ApproveOrRejectForService(e.target.value, true, 'Ambulance')} />
                                                                                <input name="btnReject" value="Reject" className="btn btn-danger ml-3" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal-0013" />
                                                                                {/* onClick={e => UpdateUserStatus(e.target.value, false)} */}


                                                                                <div class="modal fade" id="exampleModal-0013" tabindex="-1" aria-labelledby="exampleModalLabel-0013" aria-hidden="true">
                                                                                    <div class="modal-dialog">
                                                                                        <div class="modal-content">
                                                                                            <div class="modal-header">
                                                                                                <h4 class="modal-title fs-5" id="exampleModalLabel-0013">Reason Of Rejection</h4>
                                                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                                                                                            </div>
                                                                                            <div class="modal-body">

                                                                                                <textarea rows="5" col="46" placeholder='Type reason of rejection for sending mail......' value={reason} onChange={e => setReason(e.target.value)}></textarea>
                                                                                            </div>
                                                                                            <div class="modal-footer">

                                                                                                <button type="button" class="btn btn-primary" onClick={e => rejectApplication(e.target.value, false, 'Ambulance')}>Confirm</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : <input type="Submit" name="btnApprove" value={ambulanceProfileData.is_active ? "Block" : "Active"} className={ambulanceProfileData.is_active ? "btn btn-danger" : "btn  btn-success"} onClick={e => ChangeUserStatus(!ambulanceProfileData.is_active, 'Ambulance')} />
                                                                        }

                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row ">
                                                            <div className="col-lg-9">
                                                                <Form.Group as={Col} controlId="tele" className='mb-3' >
                                                                    <Form.Label>Headline</Form.Label>
                                                                    <Form.Control name="Headline" value={ambulanceProfileData.agency_name} />

                                                                </Form.Group>

                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Owner Name</Form.Label>
                                                                    <Form.Control as="textarea" rows={2} name="details" value={ambulanceProfileData.owner_name} />
                                                                </Form.Group>

                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Own Type</Form.Label>
                                                                    <Form.Control as="textarea" rows={2} name="details" value={ambulanceProfileData.own_type} />
                                                                </Form.Group>

                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>mobile</Form.Label>
                                                                    <Form.Control as="textarea" rows={2} name="details" value={ambulanceProfileData.mobile} />
                                                                </Form.Group>

                                                                <Form.Group as={Col} controlId="tele" className='mb-3'>
                                                                    <Form.Label>Email</Form.Label>
                                                                    <Form.Control as="textarea" rows={2} name="details" value={ambulanceProfileData.email} />
                                                                </Form.Group>
                                                            </div>
                                                            {/*ambulance list  */}
                                                            <div>
                                                                <h4 className='margin-mb-30'>Status: Approved Ambulances</h4>
                                                                <table class="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Ambulance Type</th>
                                                                            <th scope="col">Vehicle number</th>
                                                                            <th scope="col">Operational City</th>
                                                                            {/* <th scope="col">operational hours</th> */}
                                                                            <th scope="col">Charge per KM </th>
                                                                            <th scope="col">Advance Charge</th>
                                                                            <th scope="col">Document</th>
                                                                            <th scope="col">Details</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {ambulanceProfileData.ambulances.approvedAmbulances && ambulanceProfileData.ambulances.approvedAmbulances.length > 0 ? (
                                                                            ambulanceProfileData.ambulances.approvedAmbulances.map((key, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{key.ambulance_type}</td>
                                                                                    <td>{key.vehicle_number}</td>
                                                                                    <td>{key.operational_city}</td>
                                                                                    {/* <td>{key.availabilities && key.availabilities.length > 0 ? key.availabilities[0].start_time : ""}</td> */}
                                                                                    <td>{key.charge_per_km}</td>
                                                                                    <td>{key.advance_charge}</td>
                                                                                    {/* <td><a href={(key.documents && key.documents.length > 0) ? key.documents[0].doc_url : ""} target="_blank">View</a></td> */}
                                                                                    <td>
                                                                                        {key.documents && key.documents.length > 0 ? (
                                                                                            key.documents.map((doc, docIndex) => (
                                                                                                <a key={docIndex} href={doc.doc_url} target="_blank">
                                                                                                    <i className="fas fa-eye"></i> {" "}
                                                                                                </a>
                                                                                            ))
                                                                                        ) : (
                                                                                            "No Documents"
                                                                                        )}
                                                                                    </td>
                                                                                    <td><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal" onClick={() => getAmbulanceDetailById(key.id)}>View</button></td>
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="7">No Data Found</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>

                                                                </table>
                                                            </div>

                                                            <div>
                                                                <h4 className='margin-mb-30'>Status: New ambulance Registration Request</h4>
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Ambulance Type</th>
                                                                            <th scope="col">Vehicle number</th>
                                                                            <th scope="col">Operational City</th>
                                                                            <th scope="col">Charge per KM</th>
                                                                            <th scope="col">Advance Charge</th>
                                                                            <th scope="col">Document</th>
                                                                            <th scope="col">Details</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {(ambulanceProfileData.ambulances.pendingAmbulances && ambulanceProfileData.ambulances.pendingAmbulances.length > 0) ?
                                                                            ambulanceProfileData.ambulances.pendingAmbulances.map((key, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{key.ambulance_type}</td>
                                                                                    <td>{key.vehicle_number}</td>
                                                                                    <td>{key.operational_city}</td>
                                                                                    <td>{key.charge_per_km}</td>
                                                                                    <td>{key.advance_charge}</td>
                                                                                    <td>
                                                                                        {key.documents && key.documents.length > 0 ? (
                                                                                            key.documents.map((doc, docIndex) => (
                                                                                                <a key={docIndex} href={doc.doc_url} target="_blank">
                                                                                                    <i className="fas fa-eye"></i> {" "}
                                                                                                </a>
                                                                                            ))
                                                                                        ) : (
                                                                                            "No Documents"
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#myModal"
                                                                                            onClick={() => getAmbulanceDetailById(key.id)}
                                                                                        >
                                                                                            View
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            )) : (
                                                                                <tr>
                                                                                    <td colSpan="8">No Data Found</td>
                                                                                </tr>
                                                                            )}
                                                                    </tbody>
                                                                </table>
                                                            </div>


                                                            <div>
                                                                <h4 className='margin-mb-30'>Status: Rejected Ambulance List </h4>
                                                                <table class="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <td scope="col">Ambulance Type</td>
                                                                            <th scope="col">Vehicle Number</th>
                                                                            <th scope="col">Operational City</th>
                                                                            {/* <th scope="col">operational hours</th> */}
                                                                            <th scope="col">Charge per KM </th>
                                                                            <th scope="col">Advance Charge</th>
                                                                            <th scope="col">Document</th>
                                                                            <th scope="col">Details</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {(ambulanceProfileData.ambulances.rejectedAmbulances && ambulanceProfileData.ambulances.rejectedAmbulances.length > 0) ? ambulanceProfileData.ambulances.rejectedAmbulances.map((key, index) => (
                                                                            <tr>
                                                                                <td>{key.ambulance_type}</td>
                                                                                <td>{key.vehicle_number}</td>
                                                                                <td>{key.operational_city}</td>
                                                                                {/* <td>{(key.availabilities && key.availabilities.length > 0) ? key.availabilities[0].start_time : ""}</td> */}
                                                                                <td>{key.charge_per_km}</td>
                                                                                <td>{key.advance_charge}</td>
                                                                                {/* <td><a href={(key.documents && key.documents.length > 0) ? key.documents[0].doc_url : ""} target="_blank">View</a></td> */}
                                                                                <td>
                                                                                    {key.documents && key.documents.length > 0 ? (
                                                                                        key.documents.map((doc, docIndex) => (
                                                                                            <a key={docIndex} href={doc.doc_url} target="_blank">
                                                                                                <i className="fas fa-eye"></i> {" "}
                                                                                            </a>
                                                                                        ))
                                                                                    ) : (
                                                                                        "No Documents"
                                                                                    )}
                                                                                </td>
                                                                                <td><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal" onClick={() => getAmbulanceDetailById(key.id)}>View</button></td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan="7">No Data Found</td></tr>}

                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>
                                                    </> : "Not a Ambulance"

                                            }

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>



                </section>

                {/* <!-- Modal --> */}
                <div id="myModal" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">


                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">Ambulance Details</h4>
                                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">

                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div class="fd_input mb-2">
                                            <div class="form-group" >
                                                <label>Ambulance Type</label>
                                                <input type="text" name="ambulance_type" class="form-control" placeholder="" value={ambulanceDetail.ambulance_type} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-3">
                                        <div class="fd_input">
                                            < div class="form-group">
                                                <label>Vehicle Number</label>
                                                <input type="text" name="vehicle_number" class="form-control" placeholder="" value={ambulanceDetail.vehicle_number} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div class="fd_input">
                                            <div class="form-group">
                                                <label>Vehicle Brand</label>
                                                <input type="text" name="vehicle_make" class="form-control" placeholder="" value={ambulanceDetail.vehicle_make} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div class="fd_input">
                                            <div class="form-group">
                                                <label> Vehicle Modal</label>
                                                <input type="text" name="vehicle_model" class="form-control" placeholder="" value={ambulanceDetail.vehicle_model} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div class="fd_input">
                                            <div class="form-group">
                                                <label> Operational State</label>
                                                <input type="text" name="operational_state" class="form-control" placeholder="" value={ambulanceDetail.operational_state} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div class="fd_input">
                                            <div class="form-group">
                                                <label> Operational City</label>
                                                <input type="text" name="operational_city" class="form-control" placeholder="" value={ambulanceDetail.operational_city} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div class="fd_input">
                                            <div className="form-group">
                                                <label>Charge type</label>
                                                <input type="text" name="charge_type" class="form-control" placeholder="" value={ambulanceDetail.charge_type} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div class="fd_input">
                                            <div class="form-group">
                                                <label>Flat Charge</label>
                                                <input type="text" name="flat_charge" class="form-control" placeholder="" value={ambulanceDetail.flat_charge} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div class="fd_input">
                                            <div class="form-group">
                                                <label>Charge per km</label>
                                                <input type="number" name="charge_per_km" class="form-control" placeholder="" value={ambulanceDetail.charge_per_km} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div class="fd_input">
                                            <div class="form-group">
                                                <label>Advance Charge</label>
                                                <input type="number" name="advance_charge" class="form-control" placeholder="" value={ambulanceDetail.advance_charge} />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    {/* <div class="col-lg-12">
                                        <div class="fd_input">
                                            <div class="form-group">
                                                <label>Availability</label>
                                                <div className='border-spe'>


                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Days</th>
                                                                <th scope="col">From Time</th>
                                                                <th scope="col">To Time</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (ambulanceDetail.availabilities != null && ambulanceDetail.availabilities != undefined) &&
                                                                ambulanceDetail.availabilities.map((val, ind) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{val.day == 0 ? 'Sunday' : val.day == 1 ? 'Monday' : val.day == 2 ? 'Tuesday' : val.day == 3 ? 'Wednesday' : val.day == 4 ? 'Thursday' : val.day == 5 ? 'Friday' : val.day == 6 ? 'Saturday' : val.day == 7 ? "All day" : ''}</td>
                                                                            <td>{val.start_time}</td>
                                                                            <td>{val.end_time}</td>

                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr /> */}

                                    <div className='col-lg-12 mt-3'>
                                    <div class="col-lg-12">
                                        <label>Equipqed With</label>
                                        <div className='fd_input'>
                                            <div className='truper border-spe'>

                                                {ambulanceDetail.equipped_with && ambulanceDetail.equipped_with.map((val) => {
                                                    return (
                                                        <div>{val}</div>
                                                    );
                                                })

                                                }
                                            </div>
                                        </div>
                                        </div>

                                    </div>

                                    <div class="col-lg-12 mt-3">
                                        <div class="col-lg-12">
                                            <div class="fd_input">
                                                <div class="form-group">
                                                    <label>Ambulance Pictures</label>
                                                    <div className='border-spe'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Picture Type</th>
                                                                    <th scope="col">Link</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(ambulanceDetail.ambulance_pic != null && ambulanceDetail.ambulance_pic != undefined) &&
                                                                    <tr>
                                                                        <td>Ambulance Picture</td>
                                                                        <td><a href={ambulanceDetail.ambulance_pic} target="_blank">View</a></td>
                                                                    </tr>
                                                                }
                                                                {(ambulanceDetail.exterior_image != null && ambulanceDetail.exterior_image != undefined) &&
                                                                    <tr>
                                                                        <td>Ambulance External Picture</td>
                                                                        <td><a href={ambulanceDetail.exterior_image} target="_blank">View</a></td>
                                                                    </tr>
                                                                }
                                                                {(ambulanceDetail.interior_image != null && ambulanceDetail.interior_image != undefined) &&
                                                                    <tr>
                                                                        <td>Ambulance Internal Picture</td>
                                                                        <td><a href={ambulanceDetail.interior_image} target="_blank">View</a></td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-lg-12 mt-3">
                                    <div class="col-lg-12">
                                        <div class="fd_input">
                                            <div class="form-group">

                                                <div className='border-spe'>
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Document Type</th>
                                                                <th scope="col">Title</th>
                                                                <th scope="col">Link</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (ambulanceDetail.documents != null && ambulanceDetail.documents != undefined) && ambulanceDetail.documents.map((val, ind) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{val.doc_type}</td>
                                                                            <td>{val.title}</td>
                                                                            <td><a href={val.doc_url} target="_blank">View</a></td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-default" data-bs-toggle="modal" data-bs-target="#myModal-001">Reject</button>
                                {ambulanceDetail.approval_status === 'Pending' && <button type="button" class="btn btn-info" onClick={() => ApproveOrRejectAmbulance(ambulanceDetail.id, 'Approved', '')} >Approve</button>}
                            </div>

                        </div>

                    </div>
                </div>

                {/* Rejection modal */}
                <div id="myModal-001" class="modal fade" role="dialog">
                    <div class="modal-dialog">

                        {/* <!-- Modal content--> */}
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title fs-5" id="myModal-001">Reason for Rejection</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <textarea rows="5" cols="46" placeholder="Type the reason for rejection for sending mail..." value={reason} onChange={e => setReason(e.target.value)}></textarea>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-primary" onClick={() => ApproveOrRejectAmbulance(ambulanceDetail.id, 'Rejected', '')}>Confirm</button>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}
export default UserProfile;
