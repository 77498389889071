/* import { useNavigate } from 'react-router'
import { Link } from "react-router-dom";

const Header=()=>{
    const navigate = useNavigate()

    const onClickLogOut = (e) => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/')
    }
    return(<>
       <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars"></i></a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        
          <Link to="/dashboard" className="nav-link">Admin Panel</Link>
       
      </li>
    </ul>

    <ul className="navbar-nav ml-auto">
        <li className="nav-item">
            <div className="user-panel d-flex">
                <div className="image img-circle" >
                    
                    <i className='  fa fa-user-circle  mdi mdi-account mdi-24px '></i>
                </div>
                <div className="info">
                    <a href="#/dashboard" className="d-block">Admin</a>
                </div>
            </div>
        </li>
        <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
                <i className="fa fa-cog"></i>               
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
               
                <div className="dropdown-divider"></div>
                <a onClick={onClickLogOut} className="dropdown-item logout">
                    Logout              
                </a>
            </div>
                
        </li>




    </ul>
  </nav>
 
   
    </>);
}
export default Header; */
import { useNavigate } from 'react-router-dom'; // Import from 'react-router-dom' instead of 'react-router'
import { Link } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  
  const onClickLogOut = (e) => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/');
  };

  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" role="button">
              <i className="fas fa-bars"></i>
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/dashboard" className="nav-link">Admin Panel</Link>
          </li>
        </ul>

        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel d-flex">
              <div className="image img-circle">
                {/* Assuming you want to display an icon here */}
                <i className="fa fa-user-circle mdi mdi-account mdi-24px"></i>
              </div>
              <div className="info">
                <a href="#/dashboard" className="d-block">Admin</a>
              </div>
            </div>
          </li>
          {/* Navbar Search */}
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
              <i className="fa fa-cog"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ left: "inherit", right: "0px" }}>
              <div className="dropdown-divider"></div>
              <a onClick={onClickLogOut} className="dropdown-item logout">
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
