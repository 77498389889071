
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from "react-router-dom"
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';

import { Loader, Placeholder } from 'rsuite';
import { queries } from '@testing-library/react';
let PageSize = 10;

const baseUrl = process.env.REACT_APP_API_URL

const Query = () => {
    const token = localStorage.getItem("token")
    const location = useLocation();
    const [Period, setPeriod] = useState("ThisYear");
    const [Concern, SetConcern] = useState("");
    const [ToDate, setToDate] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [SearchBy, setSearchBy] = useState("");

    const [userData, setUserData] = useState([]);

    const [totalUser, setTotalUser] = useState("");

    const [isSending, setIsSending] = useState(false)
    const [isLoaded, SetisLoaded] = useState(true)
    const [allUserData, setAllUserData] = useState([])

    //paging
    const [currentPage, setCurrentPage] = useState(1);
    const [mailToReply, setMailToReply] = useState("")
    const [queryid, setQueryid] = useState("")
    const [replyMsg, setReplyMsg] = useState("")

    const [short_des, setShortdes] = useState("");
    const [description, setDescription] = useState("")
    useEffect(() => {

        if (isSending) {
            setIsSending(false);

        }
        var p = Period;
        searchData(Period, Concern, ToDate, FromDate, SearchBy)


    }, [isSending])

    const searchData = async (Period, Concern, ToDate, FromDate, SearchBy) => {

        var result
        result = await APIHandler.get(`/dashboard/admin/query`)

        setTotalUser(result.message);
        setAllUserData(result.data)
        setUserData(result.data);

        SetisLoaded(true)
        location.state = undefined;
    }

    //show popup for reply
    const ReplyPopup = (id, email) => {
        setMailToReply(email)
        setQueryid(id)
        window.$("#exampleModal").modal("show")


    }
    const ReplyToQuery = (e) => {

        window.$("#exampleModal").modal("show")
        var data = {
            "id": queryid,
            "email": mailToReply,
            "reply_msg": replyMsg
        }

        SendReply(data).then(result => {

            window.$("#exampleModal").modal("hide")
        })
        window.$("#modalepriscription").modal("hide")

    }

    const SendReply = async (reqdata) => {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            }


            const response = await axios.put(`${baseUrl}/feedback/reply/${queryid}`, JSON.stringify(reqdata), headers).then((response) => {
                searchData()
                setReplyMsg("")
                if (response.data.success === true)
                    toast.success("Reply sent successfully")
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    const ShowQueryDetails = (short_description, description) => {
        setDescription(description)
        setShortdes(short_description)
        window.$("#detailsModal").modal("show")

    }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return userData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, userData]);


    return (
        <div>
            <Layout />
            <div className="content-wrapper my_wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Query</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Query</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="row">


                        {Period === "Custom" &&
                            <>
                                <div className="col-lg-2">
                                    <label>From Date</label>
                                    <input type="date" className="form-control" placeholder="From Date" value={FromDate} onChange={(e) => setFromDate(e.target.value)} />
                                </div>
                                <div className="col-lg-2">
                                    <label>To Date</label>
                                    <input type="date" className="form-control" placeholder="To Date" value={ToDate} onChange={(e) => setToDate(e.target.value)} />
                                </div>

                            </>
                        }
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-primary mt-4">
                                <div className="card-header">
                                    <h3 className="card-title">Queries</h3>
                                </div>
                                <div className="card-body">
                                    <table className="table table-striped  table-bordered " >
                                        <thead>
                                            <tr>
                                                <th>
                                                    Query
                                                </th>
                                                <th>
                                                    Status
                                                </th>
                                                <th>
                                                    Created On
                                                </th>
                                                <th>
                                                    Email
                                                </th>

                                                <th>
                                                    Attachment
                                                </th>
                                                <th>
                                                    Action
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoaded ? (
                                                    (currentTableData !== null && currentTableData.length > 0) ?
                                                        currentTableData.map(obj => (
                                                            <tr>
                                                                <td><a onClick={() => ShowQueryDetails(obj.short_description, obj.description)} className='ml-1 pointer' title='Reply '>
                                                                    {obj.short_description.length > 100 ? `${obj.short_description.substring(0, 60)}...` : obj.short_description} </a></td>
                                                                <td>{obj.status}</td>
                                                                <td>
                                                                    {moment(obj.created_at).format("YYYY/MM/DD")}
                                                                </td>
                                                                <td>{obj.email} </td>
                                                                <td><a href={obj.attachment} target="_blank"> view</a></td>
                                                                {/* <td><a target="_blank" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"> Reply</a></td> */}

                                                                <td><a onClick={() => ReplyPopup(obj.id, obj.email)} className='ml-1 pointer' title='Reply '> Reply</a></td>
                                                            </tr>
                                                        )) : <tr><td colSpan={8}>No data found</td></tr>) : <tr><td colSpan={8}>  <div className="loader"></div></td></tr>}

                                        </tbody>
                                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h6 class="modal-title fs-5" id="exampleModalLabel"> <b>Reply On Query ({queryid})  </b></h6>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div>

                                                            <textarea rows="5" cols="60" placeholder=' Type here to reply' value={replyMsg} onChange={e => setReplyMsg(e.target.value)} ></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary hoverblue" data-bs-dismiss="modal" onClick={() => ReplyToQuery(queryid)} >Send</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="modal fade" id="detailsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h6 class="modal-title fs-5" id="exampleModalLabel"> <b>Details   </b></h6>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div>
                                                            <p><b>{short_des}</b></p>
                                                            <p>{description}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </table>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={userData.length}
                                        pageSize={PageSize}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </div>
    )
}
export default Query
