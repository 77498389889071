
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from "react-router-dom"
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';

import { Loader, Placeholder } from 'rsuite';
import { queries } from '@testing-library/react';
let PageSize = 10;


const ViewContactUs=()=> {
    const location = useLocation();
    const [Period, setPeriod] = useState("ThisYear");
    const [Concern, SetConcern] = useState("");
    const [ToDate, setToDate] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [SearchBy, setSearchBy] = useState("");

    const [userData, setUserData] = useState([]);
    
    const [totalUser, setTotalUser] = useState("");

    const [isSending, setIsSending] = useState(false)
    const [isLoaded, SetisLoaded] = useState(true)
    const [allUserData, setAllUserData] = useState([])

    //paging
    const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {

        if (isSending) {
            setIsSending(false);
        }
        var p = Period;
      
        searchData(Period, Concern, ToDate, FromDate, SearchBy)
       

    }, [isSending])

    const searchData = async (Period, Concern, ToDate, FromDate, SearchBy) => {
      
       const result = await APIHandler.get(`/contactus/?concern_type=${Concern}&period=${Period}&search_key=${SearchBy}&from_date=${FromDate}&to_date=${ToDate}`)
        if(result.data !== null && result.data !== undefined){
        setTotalUser(result.message);
        setAllUserData(result.data)
        setUserData(result.data);       
        SetisLoaded(true)
        location.state = undefined;
        }
    }

   
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return userData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, userData]);


  return (
    <div>
    <Layout />
        <div className="content-wrapper my_wrapper">
            <div className="content-header">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Contact Us</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Contact Us</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section className="content">
            <div className="row">
                    <div className="col-lg-2">
                        <label>Concern Details</label>
                        <select className="form-control" name="" value={Concern} onChange={(e) => SetConcern(e.target.value)}>
                        <option value="">All</option>
                            <option value="Want to be a partner">Want to be a partner</option>
                            <option value="Login Issue">Login Issue</option>
                            <option value="Payment Issue">Payment Issue</option>
                        </select>
                    </div>
                    <div className="col-lg-2">
                        <label>Period</label>
                        <select className="form-control" name="Period" value={Period} onChange={(e) => setPeriod(e.target.value)}>
                            <option value="">All</option>
                            <option value="ThisWeek">This Week</option>
                            <option value="ThisMonth">This Month</option>
                            <option value="ThisYear">This Year</option>
                            <option value="PreviousWeek">Last Week</option>
                            <option value="PreviousMonth">Last Month</option>
                            <option value="PreviousYear">Last Year</option>
                            <option value="Custom">Custom</option>
                        </select>
                    </div>
                    {Period === "Custom" &&
                        <>
                            <div className="col-lg-2">
                                <label>From Date</label>
                                <input type="date" className="form-control" placeholder="From Date" value={FromDate} onChange={(e) => setFromDate(e.target.value)} />
                            </div>
                            <div className="col-lg-2">
                                <label>To Date</label>
                                <input type="date" className="form-control" placeholder="To Date" value={ToDate} onChange={(e) => setToDate(e.target.value)} />
                            </div>

                        </>
                    }


                    <div className="col-lg-2">
                        <label>Search</label>
                        <input type="text" className="form-control" placeholder="Search" value={SearchBy} onChange={(e) => setSearchBy(e.target.value)} />
                    </div>
                    <div className="col-lg-2">
                        <label >Action</label>
                        <div>
                            <button className="btn btn-primary" onClick={(e) => setIsSending(true)} ><i className="fa fa-search"></i> Search </button>
                            {/* <button className="btn btn-primary ml-3"> <i className="fa fa-print"></i> </button> */}
                        </div>
                    </div>
                  
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-primary mt-4">
                            <div className="card-header">
                                <h3 className="card-title">Contact Us List</h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped  table-bordered " >
                                    <thead>
                                        <tr>
                                            <th>
                                                Name
                                            </th>
                                           
                                            <th>
                                                Mobile
                                            </th>
                                            <th>
                                                Email
                                            </th>
                                            <th>
                                                Concern Type
                                            </th>
                                            <th>
                                                Concern Details
                                            </th>
                                            <th>
                                                Contacted Date
                                            </th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoaded ? (
                                                (currentTableData !== null && currentTableData.length > 0) ?
                                                    currentTableData.map(obj => (
                                                        <tr>
                                                            <td>{obj.name}</td>
                                                            <td>{obj.phone}</td>
                                                            <td>{obj.email}</td>
                                                            <td>{obj.concern_type}</td>
                                                            <td>{obj.concern_detail} </td>
                                                            <td>
                                                                {moment(obj.createdAt).format("YYYY/MM/DD")}
                                                            </td>
                                                            
                                                        </tr>
                                                    )) : <tr><td colSpan={8}>No data found</td></tr>) : <tr><td colSpan={8}>  <div className="loader"></div></td></tr>}

                                    </tbody>
                                </table>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={userData.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </div>
    </div>
  )
}
export default ViewContactUs
