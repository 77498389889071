import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useLocation } from "react-router-dom"
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';
let PageSize = 10;
const BookingsDash = () => {

    const location = useLocation();
    let periodfromdash = (location.state !== undefined && location.state !== null) ? location.state.period : ""
    let statusfromdash = (location.state !== undefined && location.state !== null) ? location.state.status : "";
    let rcvfromDate = (location.state !== undefined && location.state !== null) ? location.state.QueryDate : "";


    const [BookingsData, setBookingsData] = useState([]);
    const [Status, setStatus] = useState(statusfromdash);
    const [Period, setPeriod] = useState(periodfromdash);
    const [ToDate, setToDate] = useState(rcvfromDate);
    const [FromDate, setFromDate] = useState(rcvfromDate);
    const [totalUser, setTotalUser] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoaded, SetisLoaded] = useState(true)

    useEffect(() => {
        setFromDate(rcvfromDate)
        setToDate(rcvfromDate)
        setPeriod(periodfromdash)
        setStatus(statusfromdash)
        searchData(Status, FromDate, ToDate, Period)
    }, []);



    const searchData = async (Status, FromDate, ToDate, Period) => {
        var result

        SetisLoaded(false)

        result = await APIHandler.get(`/dashboard/admin/bookingsdetail?&search_key=${Status}&period=${Period}&from_date=${FromDate}&to_date=${ToDate}`)
        //bookings/admin?service=${Service}&status=${Status}&ServiceProvider=${ServiceProvider}&booked_by=${BookedBy}&period=${Period}&from_date=${startDate}&to_date=${endDate}&dashboard=${QueriFromDash}
        setBookingsData(result.data);
        setTotalUser(result.message)
        SetisLoaded(true)
        location.state = null
    }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return BookingsData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, BookingsData]);




    return (<>
        <Layout />
        <div className="content-wrapper my_wrapper">
            <div className="content-header">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Bookings</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Bookings</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section className="content">


                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-primary mt-4">
                            <div className="card-header">
                                <h3 className="card-title">{totalUser}</h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped  table-bordered" >
                                    <thead>
                                        <tr>
                                            <th>
                                                Booking ID
                                            </th>
                                            <th>
                                                Created On
                                            </th>
                                            <th>
                                                Service
                                            </th>
                                            <th>
                                                Service Provider
                                            </th>
                                            <th>
                                                User
                                            </th>
                                            <th>
                                                Appointment on/ Scheduled On
                                            </th>
                                            <th>
                                                Status
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoaded ? (
                                            (currentTableData !== null && currentTableData.length > 0) ?
                                                currentTableData.map(obj => (
                                                    <tr>

                                                        <td> <Link to='/booking-detail' state={{ bookingid: obj.booking_id }}>
                                                            {obj.booking_id}
                                                        </Link> </td>
                                                        <td>{moment(obj.created_at).format("YYYY/MM/DD hh:mm A")}  </td>

                                                        <td>{obj.service}</td>
                                                        <td>{obj.service_Provider} </td>
                                                        <td>{obj.User}</td>

                                                        <td>
                                                            {moment(obj.appointment_date).format("YYYY/MM/DD hh:mm A")}
                                                        </td>
                                                        <td>{obj.status} </td>

                                                    </tr>
                                                )) : <tr><td colSpan={7}>No record found.</td></tr>) : <tr><td colSpan={7}>  <div className="loader"></div></td></tr>}
                                    </tbody>
                                </table>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={BookingsData.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />

                            </div>

                        </div>
                    </div>
                </div>



            </section>
        </div>

    </>);
}
export default BookingsDash;